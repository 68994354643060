import { default as archiveJAD0VxreDYMeta } from "/home/forge/efxpp.ch/pages/auth/archive.vue?macro=true";
import { default as _91id_93QkTkrqUWoOMeta } from "/home/forge/efxpp.ch/pages/auth/contacts/[id].vue?macro=true";
import { default as addeqnGKiEFynMeta } from "/home/forge/efxpp.ch/pages/auth/contacts/add.vue?macro=true";
import { default as _91id_93VmjMlBdDHgMeta } from "/home/forge/efxpp.ch/pages/auth/contacts/corporations/[id].vue?macro=true";
import { default as addpbZwmEGeDZMeta } from "/home/forge/efxpp.ch/pages/auth/contacts/corporations/add.vue?macro=true";
import { default as _91id_93GTuiHjnG9YMeta } from "/home/forge/efxpp.ch/pages/auth/contacts/corporations/delete/[id].vue?macro=true";
import { default as indexKCSfWv7EHXMeta } from "/home/forge/efxpp.ch/pages/auth/contacts/corporations/index.vue?macro=true";
import { default as _91id_93CqJ1lwe4DVMeta } from "/home/forge/efxpp.ch/pages/auth/contacts/delete/[id].vue?macro=true";
import { default as indexPUJG8srMJuMeta } from "/home/forge/efxpp.ch/pages/auth/contacts/index.vue?macro=true";
import { default as indextLaOqPMWt8Meta } from "/home/forge/efxpp.ch/pages/auth/index.vue?macro=true";
import { default as contactsQoCTkAvALSMeta } from "/home/forge/efxpp.ch/pages/auth/projects/[id]/contacts.vue?macro=true";
import { default as deletekvwZYNjYS4Meta } from "/home/forge/efxpp.ch/pages/auth/projects/[id]/delete.vue?macro=true";
import { default as edit5yPKfERVbhMeta } from "/home/forge/efxpp.ch/pages/auth/projects/[id]/edit.vue?macro=true";
import { default as indexKnb3kMoN1zMeta } from "/home/forge/efxpp.ch/pages/auth/projects/[id]/index.vue?macro=true";
import { default as addqwYUGLVmnWMeta } from "/home/forge/efxpp.ch/pages/auth/projects/add.vue?macro=true";
import { default as deletepLNzI6GVcDMeta } from "/home/forge/efxpp.ch/pages/auth/schedule/[id]/delete.vue?macro=true";
import { default as editPavQ0yL4MqMeta } from "/home/forge/efxpp.ch/pages/auth/schedule/[id]/edit.vue?macro=true";
import { default as indexrSfmyLcqndMeta } from "/home/forge/efxpp.ch/pages/auth/schedule/[id]/index.vue?macro=true";
import { default as deletebXDtIeDOm2Meta } from "/home/forge/efxpp.ch/pages/auth/schedule/[id]/sections/[sectionId]/delete.vue?macro=true";
import { default as editATXEiB7NQ4Meta } from "/home/forge/efxpp.ch/pages/auth/schedule/[id]/sections/[sectionId]/edit.vue?macro=true";
import { default as addmJMwJdhAs3Meta } from "/home/forge/efxpp.ch/pages/auth/schedule/[id]/sections/add.vue?macro=true";
import { default as _91projectId_93Fu1KOMhk7MMeta } from "/home/forge/efxpp.ch/pages/auth/schedule/add/[projectId].vue?macro=true";
import { default as _91id_93LqZpgQKlkUMeta } from "/home/forge/efxpp.ch/pages/auth/users/[id].vue?macro=true";
import { default as addM2Bhj2oPrdMeta } from "/home/forge/efxpp.ch/pages/auth/users/add.vue?macro=true";
import { default as _91id_93BCi9nBFSDBMeta } from "/home/forge/efxpp.ch/pages/auth/users/delete/[id].vue?macro=true";
import { default as indexuLqGTYE1tfMeta } from "/home/forge/efxpp.ch/pages/auth/users/index.vue?macro=true";
import { default as profile53TJtaM3tdMeta } from "/home/forge/efxpp.ch/pages/auth/users/profile.vue?macro=true";
import { default as indexYFDfNK2gTuMeta } from "/home/forge/efxpp.ch/pages/index.vue?macro=true";
import { default as loginslen4ONuw7Meta } from "/home/forge/efxpp.ch/pages/login.vue?macro=true";
import { default as _91id_93Tcssa3NERFMeta } from "/home/forge/efxpp.ch/pages/snapshot/schedule/[id].vue?macro=true";
export default [
  {
    name: "auth-archive",
    path: "/auth/archive",
    component: () => import("/home/forge/efxpp.ch/pages/auth/archive.vue").then(m => m.default || m)
  },
  {
    name: "auth-contacts-id",
    path: "/auth/contacts/:id()",
    component: () => import("/home/forge/efxpp.ch/pages/auth/contacts/[id].vue").then(m => m.default || m)
  },
  {
    name: "auth-contacts-add",
    path: "/auth/contacts/add",
    component: () => import("/home/forge/efxpp.ch/pages/auth/contacts/add.vue").then(m => m.default || m)
  },
  {
    name: "auth-contacts-corporations-id",
    path: "/auth/contacts/corporations/:id()",
    component: () => import("/home/forge/efxpp.ch/pages/auth/contacts/corporations/[id].vue").then(m => m.default || m)
  },
  {
    name: "auth-contacts-corporations-add",
    path: "/auth/contacts/corporations/add",
    component: () => import("/home/forge/efxpp.ch/pages/auth/contacts/corporations/add.vue").then(m => m.default || m)
  },
  {
    name: "auth-contacts-corporations-delete-id",
    path: "/auth/contacts/corporations/delete/:id()",
    component: () => import("/home/forge/efxpp.ch/pages/auth/contacts/corporations/delete/[id].vue").then(m => m.default || m)
  },
  {
    name: "auth-contacts-corporations",
    path: "/auth/contacts/corporations",
    component: () => import("/home/forge/efxpp.ch/pages/auth/contacts/corporations/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-contacts-delete-id",
    path: "/auth/contacts/delete/:id()",
    component: () => import("/home/forge/efxpp.ch/pages/auth/contacts/delete/[id].vue").then(m => m.default || m)
  },
  {
    name: "auth-contacts",
    path: "/auth/contacts",
    component: () => import("/home/forge/efxpp.ch/pages/auth/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/auth",
    component: () => import("/home/forge/efxpp.ch/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-projects-id-contacts",
    path: "/auth/projects/:id()/contacts",
    component: () => import("/home/forge/efxpp.ch/pages/auth/projects/[id]/contacts.vue").then(m => m.default || m)
  },
  {
    name: "auth-projects-id-delete",
    path: "/auth/projects/:id()/delete",
    component: () => import("/home/forge/efxpp.ch/pages/auth/projects/[id]/delete.vue").then(m => m.default || m)
  },
  {
    name: "auth-projects-id-edit",
    path: "/auth/projects/:id()/edit",
    component: () => import("/home/forge/efxpp.ch/pages/auth/projects/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "auth-projects-id",
    path: "/auth/projects/:id()",
    component: () => import("/home/forge/efxpp.ch/pages/auth/projects/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-projects-add",
    path: "/auth/projects/add",
    component: () => import("/home/forge/efxpp.ch/pages/auth/projects/add.vue").then(m => m.default || m)
  },
  {
    name: "auth-schedule-id-delete",
    path: "/auth/schedule/:id()/delete",
    component: () => import("/home/forge/efxpp.ch/pages/auth/schedule/[id]/delete.vue").then(m => m.default || m)
  },
  {
    name: "auth-schedule-id-edit",
    path: "/auth/schedule/:id()/edit",
    component: () => import("/home/forge/efxpp.ch/pages/auth/schedule/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "auth-schedule-id",
    path: "/auth/schedule/:id()",
    component: () => import("/home/forge/efxpp.ch/pages/auth/schedule/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-schedule-id-sections-sectionId-delete",
    path: "/auth/schedule/:id()/sections/:sectionId()/delete",
    component: () => import("/home/forge/efxpp.ch/pages/auth/schedule/[id]/sections/[sectionId]/delete.vue").then(m => m.default || m)
  },
  {
    name: "auth-schedule-id-sections-sectionId-edit",
    path: "/auth/schedule/:id()/sections/:sectionId()/edit",
    component: () => import("/home/forge/efxpp.ch/pages/auth/schedule/[id]/sections/[sectionId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "auth-schedule-id-sections-add",
    path: "/auth/schedule/:id()/sections/add",
    component: () => import("/home/forge/efxpp.ch/pages/auth/schedule/[id]/sections/add.vue").then(m => m.default || m)
  },
  {
    name: "auth-schedule-add-projectId",
    path: "/auth/schedule/add/:projectId()",
    component: () => import("/home/forge/efxpp.ch/pages/auth/schedule/add/[projectId].vue").then(m => m.default || m)
  },
  {
    name: "auth-users-id",
    path: "/auth/users/:id()",
    component: () => import("/home/forge/efxpp.ch/pages/auth/users/[id].vue").then(m => m.default || m)
  },
  {
    name: "auth-users-add",
    path: "/auth/users/add",
    component: () => import("/home/forge/efxpp.ch/pages/auth/users/add.vue").then(m => m.default || m)
  },
  {
    name: "auth-users-delete-id",
    path: "/auth/users/delete/:id()",
    component: () => import("/home/forge/efxpp.ch/pages/auth/users/delete/[id].vue").then(m => m.default || m)
  },
  {
    name: "auth-users",
    path: "/auth/users",
    component: () => import("/home/forge/efxpp.ch/pages/auth/users/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-users-profile",
    path: "/auth/users/profile",
    component: () => import("/home/forge/efxpp.ch/pages/auth/users/profile.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/efxpp.ch/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/home/forge/efxpp.ch/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "snapshot-schedule-id",
    path: "/snapshot/schedule/:id()",
    component: () => import("/home/forge/efxpp.ch/pages/snapshot/schedule/[id].vue").then(m => m.default || m)
  }
]