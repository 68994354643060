import * as Sentry from '@sentry/vue';

import { defineNuxtPlugin, useRuntimeConfig } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();
    const { vueApp } = nuxtApp;

    if (config.public.sentry.env === 'dev') return;

    Sentry.init({
        app: [vueApp],
        dsn: config.public.sentry.dns,
        integrations: [
            Sentry.browserTracingIntegration({ 
                router: nuxtApp.$router,
            }),
            Sentry.replayIntegration(),
        ],
        tracePropagationTargets: ['localhost', 'https://efxpp.ch/', /^\//],
        logErrors: false,
        tracesSampleRate: config.public.sentry.tracesSampleRate || 1.0,
        debug: config.public.sentry.debug || false,
        environment: config.public.sentry.env || 'dev',

        // enable exceptions to be logged to console despite logErrors being set to false (preventing them from being passed to the default Vue err handler)
        beforeSend(event, hint) {
            // Check if it is an exception, and if so, log it.
            if (event.exception) {
                console.error(`[Exception handled by Sentry]: (${hint.originalException})`, { event, hint })
            }
            // Continue sending to Sentry
            return event;
        },

    });

    vueApp.mixin(Sentry.createTracingMixins({ trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'] }))
    Sentry.attachErrorHandler(vueApp, { logErrors: false, attachProps: true, trackComponents: true, timeout: 2000, hooks: ['activate', 'mount', 'update'] });

    return {
        provide: {
            sentrySetContext: (n, context) => Sentry.setContext(n, context),
            sentrySetUser: (user) => Sentry.setUser(user),
            sentrySetTag: (tagName, value) => Sentry.setTag(tagName, value),
            sentryAddBreadcrumb: (breadcrumb) => Sentry.addBreadcrumb(breadcrumb),
        }
    }
})
