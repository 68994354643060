import * as Sentry from '@sentry/vue';

import { useNotificationStore } from '~~/store/notification';

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
        // send data to sentry
        Sentry.captureException(error);

        // show notification
        const notifications = useNotificationStore();
        notifications.push({
            type: 'error',
            title: 'Client error',
            message: error.message
        })

        // log error to console
        console.error(error);

    }

})