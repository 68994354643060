
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'

import isBetween from 'dayjs/plugin/isBetween'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'

dayjs.extend(isBetween)
dayjs.extend(updateLocale)
dayjs.extend(utc)






export default dayjs
