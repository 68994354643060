import { computed, ref } from 'vue';

import { defineStore } from 'pinia';

export const useScheduleStore = defineStore(
  'schedule', 
  () => {
    // store
    const data = ref({
      currentSchedule: undefined,
      currentViewPreferences: {
        sections: [],
        items: []
      },
      scrollPosition: {
        id: undefined,
        position: 0
      },
      filter: {
        contacts: [],
        locations: []
      },
      savingNotifications: [],
      hasLiveItem: false
    });
    
    // actions
    function setCurrentSchedule(payload) {
      data.value.currentSchedule = {...payload};
      data.value.hasLiveItem = false;
    }
    function setFilter(payload) {
      data.value.filter = {...payload};
    }
    function setViewPreference(payload) {
      data.value.currentViewPreferences = { ...payload };
    }
    function setSectionViewPreference(payload) {
      const sectionIndex = data.value.currentViewPreferences.sections.findIndex(option => option.id === payload.id);
      if (sectionIndex > -1)
        data.value.currentViewPreferences.sections.splice(sectionIndex, 1);
      data.value.currentViewPreferences.sections.push(payload)
    }
    function setItemViewPreference(payload) {
      const itemIndex = data.value.currentViewPreferences.items.findIndex(option => option.id === payload.id);
      if (itemIndex > -1)
        data.value.currentViewPreferences.items.splice(itemIndex, 1);
      data.value.currentViewPreferences.items.push(payload)
    }
    function setScrollPosition(payload) {
      data.value.scrollPosition = payload;
    }
    function addSavingNotification(payload) {
      data.value.savingNotifications.push(payload);
    }
    function cancelSavingNotification(payload) {
      const index = data.value.savingNotifications.findIndex(notification => notification.id === payload);
      if (index > -1) {
        data.value.savingNotifications.splice(index, 1);
      }
    }
    function setHasLiveItem(payload) {
      data.value.hasLiveItem = payload;
    }

    // getters
    const currentSchedule = computed(() => data.value.currentSchedule);
    const filter = computed(() => data.value.filter);
    const viewPreference = computed(() => data.value.currentViewPreferences);
    const scrollPosition = computed(() => data.value.scrollPosition);
    const numberOfSections = computed(() => data.value.currentSchedule.schedule_sections.length);
    const sectionViewPreference = function(id) {
      return data.value.currentViewPreferences.sections.find(section => section.id === id)
    };
    const itemViewPreference = function(id) {
      return data.value.currentViewPreferences.items.find(item => item.id === id)
    };
    const savingNotifications = computed(() => data.value.savingNotifications);
    const hasLiveItem = computed(() => data.value.hasLiveItem);


    return { 
      data,
      setCurrentSchedule,
      setFilter,
      setViewPreference,
      setSectionViewPreference,
      setItemViewPreference,
      setScrollPosition,
      addSavingNotification,
      cancelSavingNotification,
      setHasLiveItem,
      currentSchedule,
      filter,
      viewPreference,
      scrollPosition,
      numberOfSections,
      sectionViewPreference,
      itemViewPreference,
      savingNotifications,
      hasLiveItem
    }
  },
)

function getEmptyViewPreference() {
  return {
    id: null,
    sections: [],
    items: [],
  }
}
