import { computed, ref } from 'vue';

import { defineStore } from 'pinia';

export const useAuthStore = defineStore(
  'auth', 
    () => {
    // store
    const data = ref({
      user: {},
      userRoles: []
    });
    
    // actions
    function login(payload) {
      data.value.user = payload;
    }
    function logout() {
      data.value.user = {};
      this.$persist();
    }
    function setUser(payload) {
      data.value.user = payload;
    }
    function setUserRoles(payload) {
      data.value.userRoles = payload;
    }

    // getters
    const user = computed(() => {
      return data.value.user
    });
    const apiToken = computed(() => {
      return data.value.user ? data.value.user.api_token : undefined;
    });
    const userRole = computed(() => {
      return data.value.user && Array.isArray(data.value.user.roles) 
        ? data.value.user.roles[0] 
        : undefined;
    });
    const isAdmin = computed(() => {
      return userRole.value && userRole.value.slug === 'admin';
    });
    const isContributor = computed(() => {
      return userRole.value && userRole.value.slug === 'contributor';
    });
    const isObserver = computed(() => {
      return userRole.value && userRole.value.slug === 'observer';
    });
    const isLoggedIn = computed(() => {
      return !!data.value.user && !!data.value.user.api_token;
    });
    const userRoles = computed(() => {
      return data.value.userRoles;
    });

    return { data, login, logout, setUser, setUserRoles, user, apiToken, userRole, isAdmin, isContributor, isObserver, isLoggedIn, userRoles }
  },
  {
    persist: {
      enabled: true,
      paths: ['data.user'],
      storage: persistedState.cookiesWithOptions({
        sameSite: 'strict',
        expires: createExpirationDate(14),
      }),
    },
  }
)

function createExpirationDate(days) {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date;
}
