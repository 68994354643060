import { useAuthStore } from "~~/store/auth";

export default defineNuxtRouteMiddleware((to, _from) => {
  const store = useAuthStore();

  // Redirect anonymous user to /auth/signin if user tries to access anything but auth pages
  if (!store.isLoggedIn && to.path.includes('/auth/')) {
    return navigateTo('/login');
  }

  // /auth/signin and /auth/signup not reachable for already logged in users
  if (store.isLoggedIn && to.path === '/login') {
    return navigateTo('/auth/');
  }

});
