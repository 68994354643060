import { computed, ref } from 'vue';

import { defineStore } from 'pinia';

export const useNotificationStore = defineStore(
  'notification', 
  () => {
    // store
    const data = ref({
      notifications: [],
    });
    
    // actions
    function push(payload) {
      data.value.notifications.push({
        id: useUniqueId('notification'),
        ...payload
      });
    }
    function remove(notificationId) {
      const index = data.value.notifications.findIndex(item => item.id === notificationId);
      data.value.notifications.splice(index, 1);
    }

    // getters
    const notifications = computed(() => data.value.notifications);


    return { data, push, remove, notifications }
  },
)
