import { useScheduleStore } from '~~/store/schedule';

export default {
  scrollBehavior: (to, from) => {
    const app = useNuxtApp();
    const scheduleStore = useScheduleStore();

    // store scroll position when from schedule to schedule section edit
    if (from.name === 'auth-schedule-id' && to.name === 'auth-schedule-id-sections-sectionId-edit') {
      scheduleStore.setScrollPosition({ 
        id: from.params.id,
        position: window.scrollY
      });
    }
    // restore scroll position when from schedule section edit to schedule
    if (from.name === 'auth-schedule-id-sections-sectionId-edit' && to.name === 'auth-schedule-id') {
      if (scheduleStore.scrollPosition.id === from.params.id) {
        return new Promise((resolve) => {
          app.hooks.hookOnce('page:finish', () => {
            resolve({ left: 0, top: scheduleStore.scrollPosition.position })
          })
        })
      }
    }

    return new Promise((resolve) => {
      resolve({ left: 0, top: 0 })
    })
  }
}
